import gql from 'graphql-tag';

const tipos_documentoTableQuery = gql`
    query tipos_documento($whereCondition:TiposDocumentoWhereWhereConditions, $numberItems: Int!, $numberPage: Int!, $fieldOrder: String!, $Order: SortOrder!) {
        tipos_documento(where:$whereCondition, first: $numberItems, page: $numberPage, activo: true, orderBy:[{field: $fieldOrder, order: $Order}]) {
            data {
                id,
                nombre,
                valor,
                descripcion,
                cliente_id,
                empresa_id,
                countEmpleados,
                cliente{
                    nombre
                },
                empresa{
                    nombre
                }
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                lastItem
                hasMorePages
                lastPage
                perPage
                total
            }
        }
    }
`

const queries = { tipos_documentoTableQuery };

export default queries;
